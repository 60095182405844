import "./src/styles/global.css";
import "./src/browser/scripts.js"


function initGTM() {
  if (window.isGTMLoaded) {
    return false;
  };
  window.isGTMLoaded = true;
  const script = document.createElement('script');
  script.type = 'text/javascript';
  script.async = true;
  script.key = 'gtag-script';
  script.src = `https://www.googletagmanager.com/gtag/js?id=${process.env.GATSBY_GA}`;
  document.head.appendChild(script);
};

export const onInitialClientRender = () => {
  if (!window.location.pathname.includes('success')) {
    initGTM();
  }
}

export const onClientEntry = () => {
  if (window.location.pathname.includes('success')) {
    initGTM();
  }
};

// export const shouldUpdateScroll = ({
//   routerProps: { location },
//   getSavedScrollPosition,
// }) => {
//   const { pathname } = location;
//   // list of routes for the scroll-to-top-hook
//   const scrollToTopRoutes = [`/privacy-policy`, `/page-2`];
//   // if the new route is part of the list above, scroll to top (0, 0)
//   if (scrollToTopRoutes.includes(pathname)) {
//     window.scrollTo(0, 0);
//   }

//   return false;
// };
