import * as Sentry from "@sentry/gatsby";

Sentry.init({
  dsn: "https://6f9a5e2230930f63ab04c492edbb1305@o4507095512711168.ingest.de.sentry.io/4508839686111312",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
      networkDetailAllowUrls: [
        /^https:\/\/kimonomo/,
        /^https:\/\/admin\./,
        /^https:\/\/stripe\./,
        /^https:\/\/paypal\./,
    ],
    }),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  // tracePropagationTargets: ["localhost", /^\//],
  // Session Replay
  replaysSessionSampleRate: 1.0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  enabled: process.env.GATSBY_SENTRY === 'true',
  environment: process.env.GATSBY_SITE_URL 
  ? process.env.GATSBY_SITE_URL.replace(/^https?:\/\//, '').replace(/\/$/, '') 
  : 'kimonomo_unknown',
});